<template>
  <v-card light>
    <v-img :src="require('@/assets/articles/pma560_large.png')" class="grey lighten-2" height="400" width="100%">
      <v-row>
        <v-col cols="12"><br/></v-col>
      </v-row>
      <v-row class="pa-3 mt-12" align="center">
        <v-col cols="5" offset-md="3">
          <img src="@/assets/denon_logo.png" />
        </v-col>
        <v-col cols="6">
<!--          <div class="headline pl-2 mb-12">
            FAN SITE
          </div> -->
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" md="2" offset-md="3">
          <img src="@/assets/pma560_smaller.png" />
<!--          <span class="text-h1 display-3">
            PMA-560
          </span>-->
        </v-col>
<!--        <v-col cols="2" offset-md="5">
          <v-btn color="primary" depressed>
            Some Action
          </v-btn>
        </v-col> -->
      </v-row>
    </v-img>
  </v-card>
</template>

<script>
  export default {
    name: 'HomeBanner',
  }
</script>
